import React from "react";
import ReactDOM from "react-dom";
import { getApiHeatMap } from "services/services";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Link } from "react-router-dom";

const fetch = require("isomorphic-fetch");
const { compose, withProps, withHandlers } = require("recompose");
const {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} = require("react-google-maps");
const {
  MarkerClusterer
} = require("react-google-maps/lib/components/addons/MarkerClusterer");

const MapWithAMarkerClusterer = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyBW0xHIrT1e7VtoO7GsldiWk3p98QxSmm4&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `580px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withHandlers({
    onMarkerClustererClick: () => markerClusterer => {
      const clickedMarkers = markerClusterer.getMarkers();
      // console.log(`Current clicked markers length: ${clickedMarkers.length}`);
      // console.log(clickedMarkers);
    }
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap defaultZoom={3} defaultCenter={{ lat: 10.72833, lng: 121.525 }}>
    <MarkerClusterer
      onClick={props.onMarkerClustererClick}
      averageCenter
      enableRetinaIcons
      gridSize={1}
    >
      {props.markers.map((marker, i) => (
        <Marker
          key={marker?.alert_unique_id}
          onClick={() => {
            // alert(`${marker?.user_user_name}, ${marker?.alert_created_at}, ${marker?.eventUrl}`)
            props.toggle()
            props.mapData(marker)
          }}
          title="asdjk"
          position={{ lat: Number(marker.alarm_latitude), lng: Number(marker.alarm_longitude) }}
        />
      ))}
    </MarkerClusterer>
  </GoogleMap>
));

export class Heatmapv3 extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      dataMap: ''
    };

    this.toggle = this.toggle.bind(this);
    this.mapData = this.mapData.bind(this);
  }

  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     dataMap: ""
  //   };

  //   
  // }

  mapData(marker) {
    this.setState({
      dataMap: marker 
    });
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  componentWillMount() {
    this.setState({ markers: [] });
  }

  // const getHeatMap = async () => {
    // try {
      // let response = await getApiHeatMap();
      // console.log("res", response?.data?.data);
      // setData(response?.data?.data);
    // } catch (err) {}
  // };

  // componentDidMount() {
  //   console.log("response api")
  //   let response = getApiHeatMap();
  //   console.log("response", response)
  // }

  componentDidMount() {

    // const url = [
    //   // Length issue
    //   `https://gist.githubusercontent.com`,
    //   `/farrrr/dfda7dd7fccfec5474d3`,
    //   `/raw/758852bbc1979f6c4522ab4e92d1c92cba8fb0dc/data.json`
    // ].join("");

    const url = [`https://api.resq-app.com/v1/alarm/get-panic-event-loaction-data`]
    // const url = [`https://api-stage.resq-app.com/v1/alarm/get-panic-event-loaction-data`]

    fetch(url)
      .then(res => res.json())
      .then(data => {
        console.log("resp data", data)
        this.setState({ markers: data?.data });
      });
  }


  render() {
  console.log('this.state.markers', this.state.markers)

    return (
      <>
      <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>Alert Details</ModalHeader>
          <ModalBody>
            Name: {this.state.dataMap.user_full_name} <br/>
            Create At: {new Date(this.state.dataMap.alert_created_at).toLocaleString()} <br/>
            {/* new Date(row?.element?.createdAt).toLocaleString(), */}
            Url: <a  href={this.state.dataMap.eventUrl}  target="_blank" style={{color: "blue"}}>{this.state.dataMap.eventUrl}</a>
          </ModalBody>
          <ModalFooter>
            {/* <Button color="primary" onClick={this.toggle}>Do Something</Button>{' '} */}
            <Button color="secondary" onClick={this.toggle}>Cancel</Button>
          </ModalFooter>
        </Modal>

      <MapWithAMarkerClusterer markers={this.state.markers} toggle={this.toggle} mapData={this.mapData} />
      </>
    )
  }
}

// ReactDOM.render(<Heatmapv3 />, document.getElementById("root"));
// export default Heatmapv3
